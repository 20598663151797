import type { StoreMoney } from '../../../types.d/storeMoney';
import { ProductPrice } from '../../ui/productPrice/ProductPrice';

const ProductCardPrices = ({
  price,
  comparePrice,
  viewModeOnlyMobile,
  bundlePrice,
}: {
  price: StoreMoney;
  comparePrice?: StoreMoney;
  bundlePrice?: StoreMoney;
  viewModeOnlyMobile?: boolean;
}) => {
  const css = `
    .productCard__pricesWrapper {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      column-gap: var(--spacing-xs);
    }
    
    .productCard__price {
      margin-block-start: var(--spacing-s);
    }

    @media (min-width: 1024px) {
      .productCard__pricesWrapper {
        column-gap: var(--spacing-2xs);
      }
    
      .productCard__price {
        margin-block-start: var(--spacing-xs);
      }
    }
  `;
  const isDiscounted = comparePrice && comparePrice?.amount > price.amount;

  return (
    <>
      <style type="text/css">{css}</style>
      <div className={'productCard__pricesWrapper'}>
        {isDiscounted ? (
          <>
            <ProductPrice
              className={'productCard__price'}
              price={price}
              type="discounted"
              isLoading={false}
              viewModeOnlyMobile={viewModeOnlyMobile}
            />
            <ProductPrice
              className={'productCard__price'}
              price={comparePrice}
              type="pre-discount"
              isLoading={false}
              viewModeOnlyMobile={viewModeOnlyMobile}
            />
          </>
        ) : (
          <ProductPrice
            price={price}
            type="unit"
            isLoading={false}
            viewModeOnlyMobile={viewModeOnlyMobile}
          />
        )}
        {bundlePrice && (
          <ProductPrice
            className="text-body-small-desktop text-body-small-mobile"
            price={bundlePrice}
            type="bundle"
            isLoading={false}
            viewModeOnlyMobile={viewModeOnlyMobile}
          />
        )}
      </div>
    </>
  );
};

export default ProductCardPrices;
