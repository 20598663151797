import type { ProductCardProps } from '@we-make-websites/ui-lib';
import { QuickAddToCartStates, ProductCard } from '@we-make-websites/ui-lib';
import { useRouter } from 'next/router';
import { useContext } from 'use-context-selector';
import { storeLocale } from '@/root/constants';
import { BvInlineRating } from 'components/bv/bv-inline-rating';
import type { AlgoliaProductHit } from 'utils/format/algoliaHit';
import { CartContext } from '../cartContext/CartContext';
import { CustomerContext } from '../customerContext/CustomerContext';
import { useFeatureFlags } from '../featureFlagsProvider/FeatureFlagsContext';
import { WishlistButtonConnected } from '../wishlistButton/WishlistButtonConnected';

/**
 * Connected Product Card.
 * - Wraps the ProductCard component and adds in extra context.
 * - Saves needing to import all the required context anywhere that the Product
 *   Card component is needed.
 */
export const ProductCardConnected = (
  props: ProductCardProps & { hit?: AlgoliaProductHit }
) => {
  const { locale: routerLocale } = useRouter();
  const locale = storeLocale(routerLocale);

  const cartContext = useContext(CartContext);
  if (cartContext === undefined)
    throw new Error('ProductCardConnected must be used within a CartContext');

  const featureFlagsContext = useFeatureFlags();

  const { proCustomer } = useContext(CustomerContext);

  const { cartQuickAdd, cartLoading, quickAddedProductId } = cartContext;
  const { featureFlags } = featureFlagsContext;
  const { amazonCart, showPrice, cartEnabled, mikmakEnabled } =
    featureFlags[locale];

  const addToCart = ({ id }: { id: string }) => {
    cartQuickAdd({ productId: id });
  };

  let quickAddButtonState = QuickAddToCartStates.Active;

  if (cartLoading && quickAddedProductId === props.product?.id) {
    quickAddButtonState = QuickAddToCartStates.Disabled;
  } else if (cartLoading) {
    quickAddButtonState = QuickAddToCartStates.Passive;
  }

  const mergedProductCardProps: ProductCardProps = {
    amazonCart,
    addToCart,
    proCustomer,
    quickAddToCartState: quickAddButtonState,
    wishlistComponent: WishlistButtonConnected,
    BvRatingComponent: BvInlineRating,
    ...props,
  };

  return (
    <ProductCard
      {...mergedProductCardProps}
      hit={props.hit}
      showPrice={showPrice && !proCustomer}
      cartEnabled={cartEnabled}
      mikmakEnabled={mikmakEnabled}
    />
  );
};
