import clsx from 'clsx';
import type { BadgeData } from '../../ui/badge/Badge';
import { BadgeType, Badge } from '../../ui/badge/Badge';
import type { ProductCardProduct } from './ProductCard';

type OptionalPick<T, K extends PropertyKey> = Pick<T, Extract<keyof T, K>>;
type ProductBadgeType = OptionalPick<
  ProductCardProduct,
  'price' | 'comparePrice' | 'proOnly' | 'tags' | 'badges'
>;

export const ProductCardBadge = ({
  product,
  className,
}: {
  product: ProductBadgeType;
  className?: string;
}) => {
  const badge = getBadgeData(product);

  if (!badge) return null;

  return <Badge badge={badge} className={clsx(className)} />;
};

/**
 * Get Badge Data
 *
 * Using product data to retrieve badge type
 *
 * @param product
 * @returns
 */
const getBadgeData = (product: ProductBadgeType): BadgeData | undefined => {
  // Check Pro Only
  if (product.proOnly) {
    return {
      type: BadgeType.proOnly,
    };
  }

  // Check if on sale:
  const isDiscounted =
    product.comparePrice && product.comparePrice?.amount > product.price.amount;
  if (isDiscounted) {
    return {
      type: BadgeType.sale,
    };
  }

  /**
   * Check badges and tags for badge tags.
   * - `badges` is a string or array created using Algolia's named tags feature
   *   that turns prefixed tags e.g. `badge:something` into an object:
   *   - { badge: 'something' }
   * - `badges` can either be a string or an array, depending on whether the
   *   product has multiple `badge:` tags.
   * - We only want the first badge if its an array.
   */
  if (product.badges) {
    let label;
    if (Array.isArray(product.badges)) {
      label = product.badges[0];
    } else {
      label = product.badges;
    }

    if (!label) return;

    return {
      type: BadgeType.custom,
      label,
    };
  }

  /**
   * Check product tags for `badge:` prefixed product tags.
   * - Used when data isn't coming from Algolia, such as directly from Shopify.
   */
  if (product.tags) {
    const badgeTag = product.tags.find((tag) => {
      return tag.indexOf('badge:') === 0;
    });

    if (!badgeTag) return;

    const label = badgeTag.replace('badge:', '');

    if (!label) return;

    return {
      type: BadgeType.custom,
      label,
    };
  }
};

export default ProductCardBadge;
