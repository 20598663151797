import { useRouter } from 'next/router';
import type { StoreMoney } from '../../types.d/storeMoney';
import { formatMoney } from '../helpers';

export const useFormatMoney = (price: StoreMoney) => {
  const { locale } = useRouter();

  return formatMoney(locale, price.currencyCode, price.amount);
};

export default useFormatMoney;
