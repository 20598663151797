/**
 * Get block class (if passed as prop).
 * @param blockClass
 * @param element
 * @param modifier
 */
export const $blockClass = (
  blockClass?: string,
  element?: string,
  modifier?: string
): string => {
  if (!blockClass) {
    return '';
  }

  let elementClass = blockClass;

  if (element) {
    elementClass += `__${element}`;
  }

  if (modifier) {
    elementClass += `--${modifier}`;
  }

  return elementClass;
};

export const getCookie = (cname: string) => {
  if (typeof document === 'undefined') return '';

  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const formatMoney = (
  locale = 'en-US',
  currencyCode: string,
  amount: number
): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });

  let formatted = formatter.format(amount);

  // If the locale is 'fr-CH', move the currency symbol to the front
  if (locale === 'fr-CH') {
    if (formatted.startsWith(currencyCode)) {
      formatted =
        formatted.replace(currencyCode, '').trim() + ' ' + currencyCode;
    } else if (formatted.endsWith(currencyCode)) {
      formatted =
        currencyCode + ' ' + formatted.replace(currencyCode, '').trim();
    }
  }

  return formatted;
};

/**
 * Sanitizies a handle string.
 * - Ensures the product, collection etc. handles are correctly formatted to
 *   prevent requests that rely on handle look-ups from failing.
 * @param handle - handle to sanitize
 * @returns sanitized handle
 */
export const sanitizeHandle = (handle: string) => {
  return handle.toLowerCase();
};

/**
 * Cleans product handle.
 * - Removes `-pro` suffix from the product handle to prevent creating URLs that
 *   link to the `pro` product object.
 * @param handle {String}- product handle
 * @returns formatted product handle
 */
export const getCleanProductHandle = (handle: string) => {
  const regex = /-pro$/;
  if (handle.match(regex)) {
    return handle.replace(regex, '');
  }

  return handle;
};
