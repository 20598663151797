import { Trans } from '@lingui/react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import styles from './Badge.module.scss';

export enum BadgeType {
  proOnly = 'proOnly',
  sale = 'sale',
  custom = 'custom',
}

type ProOnlyBadge = {
  type: BadgeType.proOnly;
};

type SaleBadge = {
  type: BadgeType.sale;
};

type CustomBadge = {
  type: BadgeType.custom;
  label: string;
};

export type BadgeData = ProOnlyBadge | SaleBadge | CustomBadge;

export type BadgeProps = {
  badge: BadgeData;
  className?: string;
};

export const Badge = ({ className, badge }: BadgeProps) => {
  const router = useRouter();
  const locale = router.locale;
  const badgeClass = () => {
    const className = `badge--${badge.type}`;

    return styles[className];
  };

  let label;

  switch (badge.type) {
    case BadgeType.custom:
      label = badge.label;
      break;
    case BadgeType.proOnly:
      label = <Trans id="badge.proOnly" message="Pro Only" />;
      break;
    case BadgeType.sale:
      label = <Trans id="badge.sale" message="Sale" />;
      break;
  }

  if (label === 'Bestseller') {
    switch (locale) {
      case 'de-CH':
        label = 'Bestseller';
        break;
      case 'fr-CH':
        label = 'Best-Seller';
        break;
      case 'it-CH':
        label = 'Best Seller';
        break;
      default:
        label = 'Bestseller';
        break;
    }
  }

  return (
    <div className={clsx(styles.badge, badgeClass(), className)}>
      <p
        className={clsx(
          styles.badge__textContent,
          'text-utility-utility-small-mobile',
          'text-utility-utility-small-desktop'
        )}
      >
        {label}
      </p>
    </div>
  );
};
