import clsx from 'clsx';
import Link from 'next/link';
import type { ElementType, MouseEvent, ReactNode } from 'react';
import styles from './Button.module.scss';

export type ButtonProps = {
  children: ReactNode;
  component?: ElementType;
  to?: string;
  type?: 'submit' | 'button' | 'reset';
  modifiers?: string | string[];
  isLoading?: boolean;
  iconPosition?: 'left' | 'right';
  onClick?: (event: MouseEvent) => void;
};

/**
 * Set up Button component.
 */
export const Button = ({
  children,
  component,
  to = '',
  type = 'submit',
  modifiers,
  isLoading = false,
  iconPosition,
  onClick,
  className,
  ...rest
}: ButtonProps & React.HTMLProps<HTMLButtonElement>) => {
  /**
   * Convert the modifier prop into class strings.
   * @param modifiersProp - prop value for modifiers.
   * @returns string for modifier classes
   */
  const getModifierClasses = (
    modifiersProp: ButtonProps['modifiers']
  ): string => {
    if (!modifiersProp) {
      return '';
    }

    let modifierList: string[] = [];

    if (Array.isArray(modifiersProp)) {
      modifierList = modifiersProp;
    } else {
      modifierList = modifiersProp.split(/[\s,]/);
    }

    return modifierList
      .map((modifierItem: string) => {
        const className = `button--${modifierItem}`;
        return styles[className];
      })
      .join(' ');
  };

  /**
   * Generate class string for icon position modifier.
   * @param iconPosition - prop value for icon position.
   * @returns string for modifier class
   */
  const getIconModifierClass = (
    iconPosition: ButtonProps['iconPosition']
  ): string => {
    if (!iconPosition) return '';

    const className = `icon--${iconPosition}`;
    return styles[className];
  };

  const baseModifierClasses = getModifierClasses(modifiers);
  const iconModifierClass = getIconModifierClass(iconPosition);

  const defaultElement = rest.href || to ? Link : 'button';
  const Root: React.ElementType = component ?? defaultElement;

  return (
    <Root
      className={clsx(
        styles.button,
        baseModifierClasses,
        iconModifierClass,
        className,
        {
          'is-loading': isLoading,
        }
      )}
      type={Root === 'button' ? type : undefined}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Root>
  );
};
