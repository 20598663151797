import { t } from '@lingui/macro';
import type { StoreLocale } from '@store-constants';
import {
  isChLocale,
  storeLocale,
  WELLASTORE_PROFESSIONAL_URL,
} from '@store-constants';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import type { ButtonProps } from '../button/Button';
import { Button } from '../button/Button';

import styles from './AddToCart.module.scss';

export interface AddToCartProps {
  product: ATCProductProps;
  viewModeOnlyMobile?: boolean;
  /**
   * Is AMAZON Cart enabled.
   */
  amazonCart?: boolean;
  /**
   * Is Back In Stock enabled.
   */
  backInStock?: boolean;
  /**
   * Used to identify pro customers.
   */
  proCustomer: boolean;
  /**
   * Is product variant already added to cart or not.
   * Used just for Quick Shop Tray.
   */
  variantAlreadyInCart?: boolean;
  /**
   * Where is the Add to Cart used, defaults to 'pdp'.
   */
  placement?: 'pdp' | 'card' | 'inline_card' | 'recommended_essentials_card';
  state?: QuickAddToCartStates;
  addToCart?: () => void;
  className?: string;
  /**
   * Identify if store has mikmak integration
   */
  mikmakEnabled?: boolean;
  cartEnabled?: boolean;
}

interface ATCProductProps {
  handle: string;
  /**
   * Used to identify pro only products.
   */
  proOnly?: boolean;
  /**
   * ASIN data populated in product metafield.
   */
  asin?: string;
  /**
   * Url of the product in ULTA Store populated in metafield.
   */
  ultaProductUrl?: string;
  /**
   * Custom Url of the product populated in metafield.
   */
  customBuyNowLabel?: string;
  /**
   * Custom label of the product populated in metafield.
   */
  customBuyNowUrl?: string;
  /**
   * Is selected variant available for sale.
   */
  availableForSale?: boolean;
  barcode?: string;
}

export enum QuickAddToCartStates {
  Disabled,
  Passive,
  Active,
}

/**
 * AddToCart Component.
 */
export const AddToCart = (props: AddToCartProps) => {
  const { locale: routerLocale } = useRouter();
  const locale = storeLocale(routerLocale);

  if (
    locale === 'it-IT' &&
    !(props.product.customBuyNowLabel && props.product.customBuyNowUrl)
  ) {
    // client wants to hide the add to cart button for it-IT locale
    // if customBuyNowLabel or customBuyNowUrl is not provided
    // https://dentsu-poland.atlassian.net/browse/WMN-7574?focusedCommentId=988268
    return null;
  }

  const { buttonLabel, ...rest } = getAddToCartProps({
    locale,
    ...props,
  });

  return <Button {...rest}>{buttonLabel}</Button>;
};

const getButtonClassName = ({ placement, className = '' }: AddToCartProps) => {
  if (placement === 'pdp') {
    return clsx(styles.addToCart, className);
  }

  return className;
};

const getButtonModifiers = ({
  placement,
  viewModeOnlyMobile,
}: AddToCartProps) => {
  switch (placement) {
    case 'card':
      return [
        'white',
        'outlined',
        'mini-mobile',
        'block',
        viewModeOnlyMobile ? 'mobileOnly' : '',
      ];

    case 'inline_card':
      return ['mini'];

    case 'recommended_essentials_card':
      return ['text', 'mini'];

    default:
      return [''];
  }
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const getAddToCartProps = (props: AddToCartProps & { locale: StoreLocale }) => {
  const {
    product,
    amazonCart,
    backInStock = false,
    className = '',
    placement = 'pdp',
    proCustomer,
    state,
    variantAlreadyInCart = false,
    addToCart,
    locale,
    mikmakEnabled,
    cartEnabled,
  } = props;

  const isCartEnabled = cartEnabled ?? true;

  const {
    proOnly: proProduct = false,
    asin,
    availableForSale = false,
  } = product;
  const proTest = !!proProduct && !proCustomer;
  const proAmazonCart = amazonCart && proCustomer;
  const consumerAmazonCartNoProductASIN = amazonCart && !proCustomer && !asin;

  const buttonProps: Partial<
    ButtonProps &
      React.HTMLProps<HTMLButtonElement> & {
        buttonLabel: React.ReactNode;
        [key: string]: unknown;
      }
  > = {
    href: '',
    type: undefined,
    target: '',
    className,
    modifiers: [''],
    onClick: undefined as AddToCartProps['addToCart'],
    disabled: false,
    buttonLabel: t({
      id: 'product.addToCart',
      message: 'Add to cart',
    }),
  };

  buttonProps.className = getButtonClassName(props);
  buttonProps.modifiers = getButtonModifiers(props);

  if (variantAlreadyInCart) {
    buttonProps.disabled = true;
    buttonProps.buttonLabel = t({
      id: 'product.added',
      message: 'Added',
    });

    return buttonProps;
  }

  if (product.ultaProductUrl) {
    buttonProps.href = product.ultaProductUrl;
    buttonProps.target = '_blank';
    buttonProps.buttonLabel = t({
      id: 'product.buyAtUlta',
      message: 'Buy Now At ULTA',
    });

    return buttonProps;
  } else if (product.customBuyNowUrl && product.customBuyNowLabel) {
    buttonProps.href = product.customBuyNowUrl;
    buttonProps.target = '_blank';
    buttonProps.buttonLabel = product.customBuyNowLabel;
    return buttonProps;
  } else if (mikmakEnabled) {
    buttonProps.className = clsx(buttonProps.className, 'swn-tag-wtb-btn');
    buttonProps['data-eans'] = product?.barcode;
    buttonProps.type = 'button';
    buttonProps.buttonLabel = t({
      id: 'product.buyNow',
      message: 'Buy Now',
    });
    buttonProps.href = undefined;
    buttonProps.target = undefined;

    return buttonProps;
  } else {
    if (proAmazonCart || consumerAmazonCartNoProductASIN || !isCartEnabled) {
      buttonProps.href =
        placement === 'pdp' ? '' : `/products/${product.handle}`;
      buttonProps.disabled = true;
      buttonProps.buttonLabel =
        placement === 'pdp'
          ? t({
              id: 'product.salonOnly',
              message: 'Salon Only',
            })
          : t({
              id: 'product.browseNow',
              message: 'Browse Now',
            });

      return buttonProps;
    }

    if (
      (isChLocale(locale) && proProduct) ||
      (locale === 'en-GB' && proProduct) ||
      (locale === 'en-GB' && proCustomer)
    ) {
      buttonProps.href =
        product.customBuyNowUrl ?? WELLASTORE_PROFESSIONAL_URL[locale];
      buttonProps.className = clsx(buttonProps.className, styles.wellaStore);
      buttonProps.target = '_blank';
      buttonProps.onClick = undefined;
      buttonProps.buttonLabel = t({
        id: 'product.buyWellastoreButton',
        message: 'Buy now on Wellastore',
      });

      return buttonProps;
    }

    if (proTest) {
      buttonProps.href = '/account/login';

      placement !== 'recommended_essentials_card' &&
        buttonProps.modifiers.push('outlined');

      buttonProps.onClick = undefined;
      buttonProps.buttonLabel = t({
        id: 'general.loginAsPro',
        message: 'Login as a Pro',
      });

      return buttonProps;
    }

    if (!availableForSale && placement === 'pdp' && backInStock) {
      buttonProps.buttonLabel = t({
        id: 'product.backInStock',
        message: 'notify me when back in stock',
      });

      return buttonProps;
    }

    if (!availableForSale) {
      buttonProps.disabled = true;
      buttonProps.buttonLabel = t({
        id: 'product.oos',
        message: 'Out Of Stock',
      });

      return buttonProps;
    }

    buttonProps.onClick = placement === 'pdp' ? undefined : addToCart;
    buttonProps.disabled = state === QuickAddToCartStates.Disabled;

    return buttonProps;
  }
};
