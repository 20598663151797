import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';

type WithoutSSRProps = {
  children: ReactElement;
};

const WithoutSSR = ({ children }: WithoutSSRProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default dynamic(() => Promise.resolve(WithoutSSR), {
  ssr: false,
});
