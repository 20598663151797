/* eslint-disable sonarjs/cognitive-complexity */
import { Trans } from '@lingui/macro';
import { localesWithBazaarVoice, storeLocale } from '@store-constants';
import clsx from 'clsx';
import type { BaseHit } from 'instantsearch.js';
import type { ImageProps } from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { ComponentType } from 'react';
import aa from 'search-insights';
import { sendDataToGTM } from '../../../../../../apps/storefront-app/src/utils/gtm/gtm';
import IconAward from '../../../icons/misc/award.svg';
import type { StoreMoney } from '../../../types.d/storeMoney';
import type { StoreProductWishlistComponent } from '../../../types.d/storeProduct';
import { AddToCart, Button, QuickAddToCartStates } from '../../ui';
import productPriceStyles from '../../ui/productPrice/ProductPrice.module.scss';
import { ProductCardBadge } from './ProductCardBadge';
import ProductCardImages from './ProductCardImages';
import ProductCardPrices from './ProductCardPrices';

type AlgoliaMeta = {
  [namespace: string]: {
    [key: string]: string | boolean | number;
  };
};

export type AlgoliaProductHit = BaseHit & {
  id: number;
  title: string;
  image: string;
  handle: string;
  price: number;
  product_image: string;
  compare_at_price: number;
  inventory_quantity: number;
  tags: string[];
  meta: AlgoliaMeta;
  named_tags: {
    [key: string]: string | boolean | number | Array<string | boolean | number>;
  };
  product_type: string;
  barcode?: string;
};

export type ProductCardProduct = {
  systemName: string;
  title: string;
  id: string;
  handle: string;
  image?: string;
  secondaryImage?: string;
  images?: ImageProps[];
  price: StoreMoney;
  comparePrice?: StoreMoney;
  availableForSale: boolean;
  url: string;
  badges?: string | string[];
  tags?: string[];
  proOnly?: boolean;
  firstAvailableVariantId?: string;
  ultaProductUrl?: string;
  customBuyNowLabel?: string;
  customBuyNowUrl?: string;
  reviews?: {
    count: number;
    rating: number;
  };
  meta?: {
    opi?: {
      primary_color_family?: string;
      secondary_color_family?: string;
      color_family_subgroup?: string;
      finish?: string;
      depth_of_color?: string;
      hex_code?: string;
      cad_color_opacity?: string;
      sku?: string;
      primary_image_alt?: string;
      hover_image_alt?: string;
      bundle_original_price?: string;
    };
    wmw?: {
      color_collection?: string;
    };
  };
  asin?: string;
  barcode?: string;
  collectionHandle?: string;
  variantTitle?: string;
  hasAwards?: boolean;
};

export type ProductCardProps = {
  product?: ProductCardProduct;
  isLoading: boolean;
  className?: string;
  color?: string;
  location?: 'collectionListing' | 'recommendations' | 'productGrid';
  viewModeOnlyMobile?: boolean;
  proCustomer?: boolean;
  amazonCart?: boolean;
  quickAddToCartState?: QuickAddToCartStates;
  addToCart?: ({ id }: { id: string }) => void;
  wishlistComponent?: ComponentType<StoreProductWishlistComponent>;
  BvRatingComponent?: ComponentType<{ slug: string }>;
  /**
   * Additional on click event handler
   * - Does not replace the default on click event handling, can be used for
   *   click tracking
   */
  additionalOnClick?: (
    event: React.MouseEvent<Element, MouseEvent>,
    product: ProductCardProduct
  ) => void;
};

const css = `
.productCard {
  display: flex;
  flex-direction: column;
  gap: 0px 0px;
  height: 100%;
  width: 100%;

  --product-footer-inline-padding: var(--spacing-l);
  --product-footer-top-margin: var(--spacing-l);
  --product-title-min-height: unset;

  /**
     * FLoating element spacing
     * - Used to provide consistent spacing for floating elements like badges and
     *   wishlist button.
     */
  --floating-element-spacing: calc(var(--spacing-xs) + var(--spacing-3xs));
}

.productCard.mobileViewOnly {
  --product-footer-inline-padding: var(--spacing-m);
  --product-footer-top-margin: var(--spacing-s);
  --product-title-min-height: 60px;
}

.productCard__imageWrapper {
  @include aspect-ratio($force: false);
  overflow: hidden;
  position: relative;
  z-index: var(--layer-flat);
}

.productCard__imageWrapper::before{
  float: left;
  padding-top: var(--ratio-percent, 100%);
  content: "";
}

.productCard__imageWrapper.collectionListing {
  /* 158 / 225 aspect ratio */
  --ratio-percent: calc(225 / 158 * 100%);
}

.productCard__imageWrapper.productGrid {
  /* 180 / 225 aspect ratio */
  --ratio-percent: calc(225 / 180 * 100%);
}

.productCard__imageWrapper.recommendations {
  /* 158 / 197 aspect ratio */
  --ratio-percent: calc(197 / 158 * 100%);
}

.productCard.icon__award {
  bottom: var(--floating-element-spacing);
  height: 24px;
  position: absolute;
  right: var(--floating-element-spacing);
  width: 24px;
}

.productCard__imageHidden {
  display: none;
}

.productCard__footer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  margin-block-start: var(--spacing-s);
  padding-inline-start: var(--spacing-m);
  padding-inline-end: var(--spacing-m);
  margin-block-end: 0;
}

.productCard__badge {
  margin-block-start: var(--floating-element-spacing);
  margin-inline-start: var(--floating-element-spacing);
  position: absolute;
  z-index: 1;
}

.productCard__priceWithBundleWrapper {
  align-items: baseline;
  display: flex;
  column-gap: var(--spacing-xs);
}

.productCard__wishlistWrapper {
  position: absolute;
  right: var(--floating-element-spacing);
  top: var(--floating-element-spacing);
  z-index: var(--layer-raised);
}

.productCard__titles {
  padding-block-end: var(--spacing-xs);
  text-decoration: none;
  min-height: var(--product-title-min-height);
}

.productCard__titles,
.productCard__systemName,
.productCard__title {
  display: block;
}

.productCard__reviews {
  margin-block-start: calc(var(--spacing-2xs) * -1);
}

.productCard__quickAddWrapper {
  cursor: pointer;
}

.productCard__quickAddPassive {
  pointer-events: none;
}

.productCard__quickAdd {
  bottom: var(--spacing-m);
  margin-block-start: var(--spacing-s);
}

/**
   * Loading state.
   */
.productCard.is-loading .productCard__imageWrapper,
.productCard.is-loading .productCard__systemName,
.productCard.is-loading .productCard__title,
.productCard.is-loading .productCard__price {
  @include loading();
}

.productCard.is-loading .productCard__price {
  margin: 0;
  width: 50%;
}

.productCard.is-loading .productCard__loadingReviews {
  @include loading();
  width: 50%;
  min-height: 16px;
}

.productCard.is-loading .productCard__quickAdd.productCard__quickAdd {
  @include loading();
  border-radius: 80px;
}

@media (min-width: 1024px) {
  .productCard:not(.productCard__mobileViewOnly) {
    --floating-element-spacing: calc(var(--spacing-m) + var(--spacing-2xs));
  }

  .productCard__imageWrapper.collectionListing {
    /* 356 / 436 aspect ratio */
    --ratio-percent: calc(436 / 356 * 100%);
  }

  .productCard__imageWrapper.productGrid,
  .productCard__imageWrapper.recommendations {
    /* 353.75 / 436 aspect ratio */
    --ratio-percent: calc(436 / 353.75 * 100%);
  }

  .productCard.icon__award:not([data-variant="small"]) {
    height: 41px;
    width: 41px;
  }

  .productCard__footer {
    margin-block-start: var(--product-footer-top-margin, 24px);
    padding-inline-start: var(--product-footer-inline-padding);
    padding-inline-end: var(--product-footer-inline-padding);
    margin-block-end: var(--spacing-l);
  }

  .productCard__reviews {
    margin-block-start: var(--spacing-2xs);
    margin-block-end: var(--spacing-xs);
  }

  .productCard__descriptionDetailsWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .productCard__quickAdd {
    margin-block-start: var(--product-footer-top-margin, 24px);
  }
  
}
`;

export const ProductCard = (
  props: ProductCardProps & {
    hit?: AlgoliaProductHit;
    showPrice?: boolean;
    cartEnabled?: boolean;
    mikmakEnabled?: boolean;
  }
) => {
  const {
    product,
    amazonCart,
    isLoading,
    className,
    color,
    location = 'collectionListing',
    viewModeOnlyMobile,
    proCustomer = false,
    quickAddToCartState = QuickAddToCartStates.Active,
    wishlistComponent: WishlistComponent,
    BvRatingComponent,
    addToCart = ({ id }) => console.log(`Add ${id} to cart`),
    additionalOnClick,
    cartEnabled,
    mikmakEnabled,
    hit,
  } = props;

  const showProductPrice = props.showPrice ?? true;

  const router = useRouter();
  const routerLocale = router.locale;
  const locale = storeLocale(routerLocale);
  const proTest = !!product?.proOnly && !proCustomer;

  if (!product || isLoading) {
    return <ProductCardLoading {...{ ...props, proTest }} />;
  }

  const barcode = hit?.barcode ?? product.barcode;

  const isCollection = router.pathname.indexOf('/collections/') > -1;

  const pushGTMData = () => {
    const productClickData = {
      event: 'productClick',
      ecommerce: {
        currencyCode: product.price.currencyCode,
        click: {
          actionField: { list: product.collectionHandle || product.url },
          products: [
            {
              name: product.title,
              id: product.meta?.opi?.sku ?? '',
              price: product.price.amount,
              brand: 'OPI Products',
              category: product.systemName,
              variant: product.variantTitle,
              dimension2:
                product.meta?.opi?.primary_color_family?.toString() ?? null,
              dimension6:
                product.meta?.opi?.secondary_color_family?.toString() ?? null,
              dimension7:
                product.meta?.opi?.color_family_subgroup?.toString() ?? null,
              dimension8: product.meta?.opi?.finish?.toString() ?? null,
              dimension9: product.meta?.opi?.depth_of_color?.toString() ?? null,
              dimension10:
                product.meta?.opi?.cad_color_opacity?.toString() ?? null,
              dimension12: product.meta?.opi?.hex_code?.toString() ?? null,
              dimension13: product.title,
              dimension14: product.systemName,
              dimension3: product.availableForSale
                ? 'IN STOCK'
                : 'OUT OF STOCK',
            },
          ],
        },
      },
    };
    sendDataToGTM(productClickData);
  };

  const clickHandler = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();

    if (isCollection) {
      pushGTMData();
    }

    router.push(
      `${product.url}${
        props?.hit?.objectID ? `?objectID=${props?.hit?.objectID}` : ''
      }${router?.query?.queryID ? `&queryID=${router?.query?.queryID}` : ''}`
    );

    if (additionalOnClick && typeof additionalOnClick === 'function') {
      additionalOnClick(event, product);
    }

    if (props?.hit?.objectID && typeof props?.hit?.objectID === 'string') {
      if (!router?.query?.queryID) {
        aa('clickedObjectIDs', {
          index: `${router.locale}--shopify_products`,
          eventName: 'Product Clicked',
          objectIDs: [props.hit.objectID],
        });
      } else {
        aa('clickedObjectIDsAfterSearch', {
          index: `${router.locale}--shopify_products`,
          eventName: 'Product Clicked After Search',
          objectIDs: [props.hit.objectID],
          queryID: router?.query?.queryID as string,
          positions: [props.hit.__position as number],
        });
      }
    }
  };

  /**
   * Normalise Image prop data.
   */

  /* If no image has been provided throw warning */
  if (!product.image && !product.images) {
    console.warn('No images provided');
  }

  /* Allow images to be provided as an array */
  const images: ImageProps[] = product.images ?? [];

  /* If image array is unset push image strings to array */
  if (!images.length) {
    if (product.image) {
      images.push({
        src: product.image,
        alt: product.meta?.opi?.primary_image_alt || '',
        width: '350',
        height: '455',
      });
    }
    if (product.secondaryImage) {
      images.push({
        src: product.secondaryImage,
        alt: product.meta?.opi?.hover_image_alt || '',
        width: '350',
        height: '455',
      });
    }
  }

  /* Ensure that any array of images is a maxmimum lenght of 2 */
  images.length = Math.min(images.length, 2);

  /*
   *Variables for Mobile Only view type components
   */
  const dynamicVariables = viewModeOnlyMobile
    ? {
        fontClasses: {
          systemName: 'text-utility-utility-small',
          title: 'text-body-2-bold-mobile',
        },
        styleClass: 'productCard__mobileViewOnly',
      }
    : {
        fontClasses: {
          systemName: 'text-utility-utility-desktop text-utility-utility-small',
          title: 'text-body-1-bold-desktop text-body-2-bold-mobile',
        },
      };

  // BazaarVoice configuration
  const shouldLoadBvScript = localesWithBazaarVoice.includes(locale);

  return (
    <>
      <style type="text/css">{css}</style>
      <div
        className={clsx(
          'productCard',
          dynamicVariables.styleClass,
          className,
          mikmakEnabled && 'product-quickview'
        )}
      >
        <div className={clsx('productCard__imageWrapper', location)}>
          <ProductCardImages
            images={images}
            onClick={clickHandler}
            color={color}
            viewModeOnlyMobile={viewModeOnlyMobile}
            backupAltText={product.title}
          />

          <ProductCardBadge product={product} className="productCard__badge" />

          {WishlistComponent && (
            <div className="productCard__wishlistWrapper">
              <WishlistComponent
                handle={product.handle}
                className={viewModeOnlyMobile ? 'small' : ''}
              />
            </div>
          )}

          {product.hasAwards && (
            <IconAward
              data-variant={viewModeOnlyMobile ? 'small' : undefined}
            />
          )}
        </div>

        <div className={clsx('productCard__footer')}>
          <h3>
            <Link
              href={product.url}
              onClick={clickHandler}
              data-color-family-primary={
                product.meta?.opi?.primary_color_family || ''
              }
              data-color-family-secondary={
                product.meta?.opi?.secondary_color_family || ''
              }
              data-color-subgroup={
                product.meta?.opi?.color_family_subgroup || ''
              }
              data-color-finish={product.meta?.opi?.finish || ''}
              data-color-depth={product.meta?.opi?.depth_of_color || ''}
              data-color-hex={product.meta?.opi?.hex_code || ''}
              data-color-system={product.meta?.wmw?.color_collection || ''}
              className="productCard__titles"
            >
              <span
                className={clsx(
                  'productCard__systemName',
                  dynamicVariables.fontClasses.systemName
                )}
              >
                {product.systemName}
              </span>
              <span
                className={clsx(
                  'productCard__title',
                  dynamicVariables.fontClasses.title
                )}
              >
                {product.title}
              </span>
            </Link>
          </h3>

          <div className="productCard__descriptionDetailsWrapper">
            {!proTest && showProductPrice && (
              <ProductCardPrices
                price={product.price}
                bundlePrice={
                  (product.meta?.opi?.bundle_original_price && {
                    amount: Number(product.meta?.opi?.bundle_original_price),
                    currencyCode: product.price.currencyCode,
                  }) ||
                  undefined
                }
                comparePrice={product.comparePrice}
                viewModeOnlyMobile={viewModeOnlyMobile}
              />
            )}
            {/* <ReviewStars
            {...product.reviews}
            viewModeOnlyMobile={viewModeOnlyMobile}
          /> */}
            {shouldLoadBvScript && BvRatingComponent && (
              <BvRatingComponent slug={product.handle} />
            )}

            <AddToCart
              product={{
                ...product,
                barcode,
              }}
              amazonCart={amazonCart}
              viewModeOnlyMobile={viewModeOnlyMobile}
              proCustomer={proCustomer}
              placement="card"
              addToCart={() => {
                addToCart({ id: product.id });
                if (props?.hit?.objectID) {
                  aa('addedToCartObjectIDs', {
                    index: `${router.locale}--shopify_products`,
                    eventName: 'Product Added To Cart',
                    objectIDs: [props?.hit?.objectID as string],
                    value: product.price.amount,
                    currency: product.price.currencyCode,
                  });
                }
              }}
              className={clsx(
                'productCard__quickAdd',
                quickAddToCartState === QuickAddToCartStates.Passive
                  ? 'productCard__quickAddPassive'
                  : ''
              )}
              state={quickAddToCartState}
              cartEnabled={cartEnabled}
              mikmakEnabled={mikmakEnabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ProductCardLoading = ({
  product,
  className,
  location = 'collectionListing',
  proTest = false,
}: ProductCardProps & { proTest: boolean }) => {
  return (
    <>
      <style type="text/css">{css}</style>
      <div className={clsx('productCard', className, 'is-loading')}>
        <div className={clsx('productCard__imageWrapper', location)}>
          <div className="productCard__imageWrapperOne">
            <div className="styles.productCard__image"></div>
          </div>
        </div>
        <div className="productCard__footer">
          <span className="productCard__titles">
            <p
              className={clsx(
                'productCard__systemName',
                'text-utility-utility-desktop',
                'text-utility-utility-small'
              )}
              aria-hidden
            >
              {product?.systemName ?? (
                <Trans id="accessibility.loading">Loading...</Trans>
              )}
            </p>
            <p
              className={clsx(
                'productCard__title',
                'text-body-1-bold-desktop',
                'text-body-2-bold-mobile'
              )}
              aria-hidden
            >
              {product?.title ?? (
                <Trans id="accessibility.loading">Loading...</Trans>
              )}
            </p>

            {!proTest && (
              <p className={productPriceStyles['productPrice--unit']}>
                <span className="productCard__price">
                  <Trans id="accessibility.loading">Loading...</Trans>
                </span>
              </p>
            )}

            <div className="productCard__loadingReviews"></div>
          </span>

          <Button
            type="button"
            disabled
            className="productCard__quickAdd"
            modifiers={['white', 'outlined', 'mini-mobile', 'block']}
          >
            <Trans id="product.addToCart">Add to bag</Trans>
          </Button>
        </div>
      </div>
    </>
  );
};
