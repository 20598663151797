'use client';

import clsx from 'clsx';
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import { Shimmer, toBase64 } from '../../utility/imageShimmer/ImageShimmer';

const ProductCardImages = ({
  images,
  onClick,
  color,
  viewModeOnlyMobile,
  backupAltText = '',
}: {
  images: ImageProps[];
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
  color?: string;
  viewModeOnlyMobile?: boolean;
  backupAltText?: string;
}) => {
  /*
  Default image background colour to white
  */
  const backgroundColor = color ?? '#ffffff';

  const css = `
    .productCard__imageWrapperOne,
    .productCard__imageWrapperTwo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.3s ease;
    }

    .productCard__imageWrapperOne {
      opacity: 1;
    }

    .productCard__imageWrapperTwo {
      opacity: 0;
    }

    .productCard__image {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }

    @media (hover: hover) {
      .productCard:hover .productCard__imageWrapperOne--fade {
        opacity: 0;
      }
    
      .productCard:hover .productCard__imageWrapperTwo--fade {
        opacity: 1;
      }
    }
  `;

  return (
    <>
      <style type="text/css">{css}</style>
      <div
        className={clsx('productCard__imageWrapperOne', {
          ['productCard__imageWrapperOne--fade']: images.length === 2,
        })}
        style={{ backgroundColor }}
      >
        <Image
          src={images[0]?.src}
          alt={images[0]?.alt || backupAltText}
          title={images[0]?.alt || backupAltText}
          fill
          sizes={
            viewModeOnlyMobile ? '158px' : '(min-width: 1024px) 500px, 50vw'
          }
          className="productCard__image"
          placeholder="blur"
          onClick={onClick}
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            Shimmer(100, 130)
          )}`}
        />
      </div>
      {images[1] && (
        <div
          className={clsx('productCard__imageWrapperTwo', {
            ['productCard__imageWrapperTwo--fade']: images.length === 2,
          })}
        >
          <Image
            src={images[1].src}
            alt={images[1].alt || backupAltText}
            title={images[1].alt || backupAltText}
            fill
            sizes={
              viewModeOnlyMobile ? '158px' : '(min-width: 1024px) 500px, 50vw'
            }
            className={clsx('productCard__image')}
            placeholder="blur"
            onClick={onClick}
            blurDataURL={`data:image/svg+xml;base64,${toBase64(
              Shimmer(100, 130)
            )}`}
          />
        </div>
      )}
    </>
  );
};

export default ProductCardImages;
